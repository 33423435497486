let lang = window.location.pathname.split("/")[1];

if(lang.length>2){
    lang = "pl";
}

window.loadMoreDynamiccpomonents = function (obj) {

    const url = "/api/handlebars";
    const target = $(obj);
    const data = target.data("loadmore");
    const json = JSON.parse(data)
    let parse = target.parent();
    if (parse.hasClass("cell-loadmore")) {
        const oldParse = parse;
        parse = parse.parent()
        oldParse.remove();
    }

    json.urlLocation = window.location.pathname;

    const base64 = window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
    const params = new URLSearchParams(base64).toString();

    target.remove()

    fetch(`/api/dynamiccomponent/${lang}/${json.component}?base=${params}`, {
        data: data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        mode: "cors",
        method: "GET",
    }).then(async function (res) {
        const data = await res.text();
        parse.append(data)

        //loadAllMain();
    });
}


function loadAll() {
    $(`[data-dynamiccomponent]`).each(function () {
        let elem = $(this);
        if (elem.isInViewport(500)) {
            let data = JSON.parse($(this).data("dynamiccomponent").replaceAll("'", "\""));
            data.urlLocation = window.location.pathname;
            let component = data.component;
            elem.removeData("dynamiccomponent");
            elem.html("Loading...");
            elem.addClass("loading");

            const params = new URLSearchParams(data).toString();
            fetch(`/api/dynamiccomponent/${lang}/${component}?${params}`, {
                data: data,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                mode: "cors",
                method: "GET",
            }).then(async function (res) {
                const data = await res.text();
                elem[0].outerHTML = data;

                loadAllMain();
            });

            // $.ajax({
            //     url: `/api/dynamiccomponent/${lang}/${component}`,
            //     type: "GET",
            //     data: data,
            //     success: function (data, status, xhr) {
            //         console.log(data, status, xhr);
            //         elem[0].outerHTML = data;
            //     },
            // })
        }
    });
}

function filterReload(custom = {}) {
    $(`[data-dynamiccomponent-filter]`).each(function () {
        let elem = $(this);
        if (elem.isInViewport(500)) {
            let data = JSON.parse($(this).data("dynamiccomponent-filter").replaceAll("'", "\""));
            data.urlLocation = window.location.pathname;
            let component = data.component;

            elem.html("Loading...");
            elem.addClass("loading");

            data = Object.assign(data, custom)

            if (dynamiccomponentFilter.order) {
                data["order"] = dynamiccomponentFilter.order;
                delete dynamiccomponentFilter.order;
            }
            data["filter"] = dynamiccomponentFilter;


            const base64 = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
            const params = new URLSearchParams(base64).toString();

            fetch(`/api/dynamiccomponent/${lang}/${component}?base=${params}`, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                mode: "cors",
                method: "GET",
            }).then(async function (res) {
                const data = await res.text();
                const x = elem.get().innerHTML = data;

                loadAllMain();
            });

            // $.ajax({
            //     url: `/api/dynamiccomponent/${lang}/${component}`,
            //     type: "GET",
            //     data: data,
            //     success: function (data, status, xhr) {
            //         console.log(data, status, xhr);
            //         elem[0].outerHTML = data;
            //     },
            // })
        }
    });
}

$(window).on("load", function () {
    loadAll();

    $(".display-grid").on("click", function () {
        if ($(".display-lines").hasClass("outline")) return;
        $(".display-lines").addClass("outline");
        $(this).removeClass("outline");
        $(".dynamiccomponent-group").addClass("layout-grid")
        filterReload({
            grid: "4|4|6|12"
        });
    })

    $(".display-lines").on("click", function () {
        if ($(".display-grid").hasClass("outline")) return;
        $(".display-grid").addClass("outline");
        $(this).removeClass("outline");
        $(".dynamiccomponent-group").removeClass("layout-grid")
        filterReload({
            grid: "12|12|12|12"
        });
    })

    $(window).on("scroll", function () {
        loadAll();
    });

    window.dynamiccomponentFilter = {}
    $(".dynamiccomponent-filter").on("submit", function (e) {
        e.preventDefault();
        $(this).trigger("change");
    })
    $(".dynamiccomponent-filter").on("change", function (e) {
        let inputs = $(this).find("input, select, textarea");
        dynamiccomponentFilter = {};
        inputs.each(function () {
            if ($(this).is("select")) {
                let $select = $(this)

                if ($select.attr("name") == "order") {
                    $(this).find("option").each(function () {
                        if ($(this).isSelected() && !$(this).hasClass("default") && this.value !== "wybierz") {
                            let val = this.value;
                            val = val.split(":");

                            if (val.length == 2) {
                                dynamiccomponentFilter["order"] = {
                                    type: val[0],
                                    by: val[1],
                                }
                            }
                        }
                    });
                } else {
                    $(this).find("option").each(function () {
                        if ($(this).isSelected() && !$(this).hasClass("default") && this.value !== "wybierz") {
                            dynamiccomponentFilter[$(this).parent().attr("name")] = this.value;
                        }
                    });
                }


            } else {
                if (this.value !== "") dynamiccomponentFilter[$(this).attr("name")] = this.value;
            }
        });
        filterReload($(".layout-grid").length >= 1 ? {
            grid: "4|4|6|12"
        } : {});
    });
});