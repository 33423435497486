export class Counter {
    constructor(obj, min, max, duration = 1000, prefix = "", suffix = "", thousandSeparator = false, callback) {
        this.destroy = false;

        this.obj = obj;
        this.count = 0;
        this.callback = callback;
        this.thousandSeparator = thousandSeparator;
        this.prefix = prefix;
        this.suffix = suffix;
        this.max = max;
        this.min = min;

        this.start = Date.now()
        this.end = this.start + duration
        this.interval = duration / (max - min);

        this.actWidth = obj.css("width") + 8;
        this.actMaxWidth = obj.css("max-width");
        this.actHeight = obj.css("height");
        this.lastUpdate = this.start;

        obj.html(prefix + (thousandSeparator ? max.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : max.toFixed(0)) + suffix)

        this.width = obj[0].clientWidth;
        this.height = obj[0].clientHeight;

        obj.css("max-width", this.width + "px");
        obj.css("width", "100%");
        obj.css("height", this.height + "px");
    }

    update() {
        let obj = this.obj;
        var time = Date.now()

        if (this.lastUpdate + (this.interval / 2) < time) {
            if (time < this.end) {
                this.count = (time - this.start) / this.interval
                this.lastUpdate = time;
            } else {
                this.destroy = true;
                this.count = this.max;
            }

            obj.html(this.prefix + (this.thousandSeparator ? this.count.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : this.count.toFixed(0)) + this.suffix);

            if (this.count >= this.max) {
                obj.css("width", this.actWidth);
                obj.css("max-width", this.actMaxWidth);
                obj.css("height", this.actHeight);

                if (this.callback) this.callback(obj);
            }
        }
    }

}