window.addEventListener("load", () => {
    $("[data-parallax]").each((i, obj) => {
        new Parallax(obj);
    })
});

class Parallax {
    constructor(obj) {
        this.obj = obj;
        this.data = {};
        this.position = {}
        this.init();
    }

    prepare() {
        const data = JSON.parse($(this.obj).data("parallax"));

        let defaults = {
            orientation: "down",
            scaleMultiplier: 0.1,
            multiplier: .25,
            static: 0,
            transition: "linear",
            transitionTime: ".5",
            delay: 0,
            offset: 0,
            maxTransition: 0,
            selector: null,
            type: "background",
            debug: false,
        };

        this.position = $(this.obj).position();
        this.data = Object.assign(defaults, data);

        if (this.data.selector != null) {
            this.data.selector = $(this.obj).find(this.data.selector);
        }
    }

    update() {
        let data = this.data;
        let obj = this.obj;

        let elementPos = scrollY - data.offset - ($(obj).offset().top - $(obj).height());

        if (data.debug) console.log(obj, elementPos);

        if (elementPos >= 0) {
            let position = elementPos * data.multiplier;
            let scrollPositionOnElement = scrollY - $(obj).offset().top + window.innerHeight;
            let scrollPositionOnElementPercent = scrollPositionOnElement / $(obj).height();

            if (elementPos > data.maxTransition && data.maxTransition > 0) return;

            let scale = 1 + (scrollPositionOnElementPercent * data.scaleMultiplier) - data.scaleMultiplier;

            if (data.type == "normal") {
                if (data.orientation === "up" || data.orientation === "down") {
                    $(obj).css("transform", `translateY(` + (data.orientation === "up" ? "-" : "") + `${position + data.static}px) scale(${scale})`);
                } else if (data.orientation === "left" || data.orientation === "right") {
                    $(obj).css("transform", `translateX(` + (data.orientation === "left" ? "-" : "") + `${position + data.static}px) scale(${scale})`);
                }

                $(obj).css("transition", `transform ${data.transitionTime}s ${data.transition}`);
                if (data.delay > 0) $(obj).css("transition-delay", data.delay);
            } else if (data.type == "background") {
                if (data.orientation === "up" || data.orientation === "down") {
                    $(obj).css("background-position-y", (data.orientation === "up" ? "-" : "") + `${position - data.static}px` );
                } else if (data.orientation === "left" || data.orientation === "right") {
                    $(obj).css("background-position-x", (data.orientation === "left" ? "-" : "") + `${position - data.static}px`);
                }

                $(obj).css("transition", `transform ${data.transitionTime}s ${data.transition}`);
                if (data.delay > 0) $(obj).css("transition-delay", data.delay);
                if (scale > 1 || data.scale<1) $(obj).css("background-size", `${scale * 100}%`);
            }

        }
    }

    init() {
        this.prepare();
        this.update();
        window.addEventListener("scroll", () => {
            this.update();
        });
    }

}