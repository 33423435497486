import {$} from "./utils.js";

$(window).on("load",function(){
	
	$("[data-font-size]").each(function(){
		const size=parseFloat(getComputedStyle(this)["font-size"]);
		const multi=parseFloat($(this).data("font-size"));
		$(this).find("*").each(function(){
			$(this).css("font-size",`${size*multi}px`);
		});
	});
});