/*

    :: DOCS [VerCode x Allsenses] ::

    ? How to use it ?
    To use it you need to add the data attribute "data-scrollanimation" to the element you want to animate,
    with the unique animation name as value.

    data-scrollanimation="[UNIQUE ANIMATION NAME]" - Declare a unique animation name for the element.


    * Example *
    <div data-scrollanimation="animation1"></div>




    ? What type of animations are available ?
    You can use all css properties, like "transform", "opacity", "width", etc.
    To declare dynamic values you can use the following scheme:

    |X to Y| - where X is the start value and Y is the end value, and requires the space before and after the "to".
    data-sa-[CSS PROPERTY] - to specify the css property you want to animate.


    * Example *
    <div data-scrollanimation="animation1" data-sa-opacity="|0 to 1|" data-sa-width="|200 to 500|px">




    ? How to work with detectors ?
    To work with detectors you need to add the sa attribute "data-sa-detector" to the element you want to use as detector,
    the detector will be the element that will trigger the animation when it is in the viewport.
    Detectors can be any element, but it is recommended to use a div with a height of 1px.
    It can be only get by the ID parameter, and the ID must be unique for each detector.

    data-sa-detector=[ID OF ELEMENT] - Declares the ID of the detector, and the detector will be the element with this ID.


    * Example *
    <div id="detector1"></div>
    <div data-scrollanimation="animation1" data-sa-detector="detector1"></div>




    ? How to use keyframes ?
    To use keyframes first of all you need to know how that works.
    Keyframes are a way to animate an element in a specific time, and you can use it to create a more complex animation.

    data-sa-[CSS PROPERTY]="|0:Q to a:W to b:E to c:R to d:T to 100:Y|"

    Where "Q, W, E, R, T, Y" are the values of the keyframes, and "0, a, b, c, d, 100" are the percentages of the animation.
    The numbers must be in order, and the values can be in any order. You must always use the ":" between the number and the value.
    And start from 0 and end with 100.


    * Example *
    <div data-scrollanimation="animation1" data-sa-width="|0:200 to 30:150 to 80:700 to 100:500|px"></div>     --> This will animate the width of the element from
                                                                                                                200px at 0% to 150px at 30% to 700px at 80% to 500px at 100%.

 */


const vcScrollAnimation = {

    options: {
        status: 'on',
    },

    animates: {},
    animatesIndex: {},

    getVisibility: function (el) {
        let data = -1;
        let offset = el.attributes['data-sa-offset'] ? parseInt(el.attributes['data-sa-offset'].value) : 0;
        if (typeof el == 'object') {
            let id = el.getAttribute("data-sa-detector");
            if (id) {
                let eld = $(`#${id}`)[0];
                if (eld !== null) {
                    el = eld;
                }
            }

            let box = el.getBoundingClientRect();
            let offsetTop = box.top + window.pageYOffset - document.documentElement.clientTop + offset;
            let height = el.clientHeight;
            let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
            let windowHeight = window.document.documentElement.clientHeight;
            data = (scrollTop + windowHeight - offsetTop) / (windowHeight + height);
        }
        return data;
    },

    animate: function () {
        let animations = $(`[data-scrollanimation]`);
        if (animations !== null) {
            animations.each(function () {
                let obj = this;
                const animateId = $(obj).attr('data-scrollanimation');
                vcScrollAnimation['animatesIndex'][animateId] = [];

                Object.keys($(obj).attributes("data-sa")).forEach(function (property, forEachIndex) {
                    const source = $(obj).attributes()[property].value.split('|');
                    let finalExpression = [];
                    let index = 0;

                    if (forEachIndex === 0) {
                        vcScrollAnimation.animatesIndex[animateId] = {
                            transfer: {},
                            properties: []
                        };
                    }

                    source.forEach(function (part) {
                        const fromToArray = part.split(' to ');
                        if (fromToArray.length > 1) {
                            const fromValue = parseFloat(fromToArray[0]);
                            const toValue = parseFloat(fromToArray[1]);
                            const tweenId = animateId + '_' + property + '_' + index.toString();

                            finalExpression.push(tweenId);
                            vcScrollAnimation.animatesIndex[animateId]['transfer'][tweenId] = function (scroll_line) {
                                let response = 0
                                if (typeof scroll_line == 'number') {

                                    if (scroll_line > 1) scroll_line = 1;
                                    if (scroll_line < 0) scroll_line = 0;

                                    // Keyframes animation (from-to)
                                    if (part.indexOf(':') > 0) {
                                        let xa = 0;
                                        let ya = fromValue;
                                        let xb = 1;
                                        let yb = 1;
                                        fromToArray.forEach(function (keypoint, keyPointIndex) {
                                            if (keyPointIndex < fromToArray.length - 1) {
                                                const positionCurrent = parseFloat(keypoint.split(':')[0]) / 100;
                                                const positionNext = parseFloat(fromToArray[keyPointIndex + 1].split(':')[0]) / 100;

                                                if (scroll_line >= positionCurrent && scroll_line <= positionNext) {
                                                    xa = positionCurrent;
                                                    ya = parseFloat(keypoint.split(':')[1]);
                                                    xb = positionNext;
                                                    yb = parseFloat(fromToArray[keyPointIndex + 1].split(':')[1]);
                                                }
                                            }
                                        });
                                        const coef = (yb - ya) / (xb - xa);
                                        const y0 = yb - coef * xb;
                                        response = coef * scroll_line + y0;
                                    } else {
                                        response = (toValue - fromValue) * scroll_line + fromValue;
                                    }
                                }
                                return response;
                            }

                            index++;
                        } else {
                            finalExpression.push(part);
                        }
                    });
                    vcScrollAnimation.animatesIndex[animateId]['properties'].push({
                        property: property,
                        expression: finalExpression
                    });
                });
            });

            $("head").append('<style>[data-scrollanimation] { transition: none !important; }</style>')
            requestAnimationFrame(vcScrollAnimation.apply);
        }
    },

    apply: function () {
        for (const anim_id in vcScrollAnimation.animatesIndex) {
            if (Object.hasOwnProperty.call(vcScrollAnimation.animatesIndex, anim_id)) {
                const node = document.querySelector(`[data-scrollanimation="${anim_id}"]`);
                if (node !== null) {
                    let scroll_line = vcScrollAnimation.getVisibility(node);
                    if (typeof vcScrollAnimation['animatesIndex'][anim_id]['properties'] == 'object') {
                        vcScrollAnimation['animatesIndex'][anim_id]['properties'].forEach(function (data) {
                            let completeTweenedValueToApply = '';
                            data.expression.forEach(function (partialValue) {
                                if (typeof vcScrollAnimation['animatesIndex'][anim_id]['transfer'][partialValue] == 'function') {
                                    completeTweenedValueToApply += vcScrollAnimation['animatesIndex'][anim_id]['transfer'][partialValue](scroll_line).toString();
                                } else {
                                    completeTweenedValueToApply += partialValue;
                                }
                            });

                            node.style[data.property.replaceAll("data-sa-", "")] = completeTweenedValueToApply;
                        });
                    }
                }
            }
        }
        if (vcScrollAnimation.options.status === 'on') {
            requestAnimationFrame(vcScrollAnimation.apply);
        }
    },

    frame: function () {
        if (vcScrollAnimation.options.status === 'on') {
            vcScrollAnimation.apply();
        }
    },

    start: function () {
        vcScrollAnimation.options.status = 'on';
        requestAnimationFrame(vcScrollAnimation.apply);
    },

    stop: function () {
        vcScrollAnimation.options.status = 'off';
    }
}

// Init
$(window).on('load', function () {
    vcScrollAnimation.animate();
});

window.vcScrollAnimation=vcScrollAnimation;