console.log(`
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⣤⣄⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣴⣶⣿⣿⣿⣿⣿⣿⣿⣷⣶⣆⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⡀⠀⠀⠀
⠀⣤⣄⠀⢠⣤⢤⣤⣤⣤⣄⣤⣤⡄⢠⣤⣤⣤⣤⣤⣄⣤⣤⣤⣄⠀⠀⣸⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⢿⣿⣶⣷⠀⠀⠀
⠀⣿⣿⣧⣈⡿⢹⣿⠏⡝⠟⢿⣿⡅⣼⠏⢸⣿⢉⡙⠇⣿⡟⣽⣿⠀⠀⣿⣿⣿⠟⠉⠀⠀⠀⠉⠁⠀⠀⠀⠙⢿⣿⣿⠀⠀⠀
⠀⣿⢿⡿⣿⡇⢸⣿⢾⡇⠀⢸⣿⣷⡟⠀⣼⣿⢾⠃⢠⣿⡷⣿⣏⠀⠀⣿⣿⡏⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⣿⣿⡄⠀⠀
⣰⣿⡀⠻⣶⢇⣾⣿⣈⣴⠇⠘⣶⡞⠀⢀⣷⣇⣈⣼⣻⣿⡁⣿⣿⡀⠀⣿⣿⣇⣤⠶⠶⣶⣆⠀⢠⣴⣤⣤⡀⠀⠿⣻⠃⠀⠀
⠙⠛⠁⠀⠙⠈⠉⠉⠉⠛⠀⠀⠙⠀⠀⠈⠉⠉⠉⠋⠙⠛⠁⠙⠛⠁⠀⢻⣿⠈⠁⠞⠛⠛⠀⠀⠻⡛⠻⠿⠧⠀⢀⠇⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢰⠟⡻⡄⠀⠀⠀⠀⢀⣀⣀⠁⠀⠀⠀⠀⣰⣵⠀⠀⠀
⠀⢀⣤⣤⣤⠀⢀⣤⣤⡀⢠⣤⡀⠀⣤⣤⣤⣄⠀⢠⣤⡄⠀⣠⡄⠀⠈⢆⠁⢿⠀⠀⠀⠀⠉⠛⠛⠛⠀⠀⠀⠀⡿⡝⠀⠀⠀
⣰⣿⠋⠹⣿⢠⣿⠋⢿⣿⡌⣿⣿⣆⢹⡏⢻⣿⣷⡈⣿⠁⢀⣿⣿⡄⠀⠈⠣⣼⡄⠀⠀⢴⣶⣿⣻⣷⠤⠀⠀⣸⠜⠀⠀⠀⠀
⣿⣿⢠⣴⣶⣿⣿⡀⢸⣿⡇⣿⢿⣿⣿⡇⣿⡿⣿⣿⣿⠀⣼⣉⣿⣇⠀⠀⠀⠘⣿⡆⠀⠀⠳⠭⠽⠵⠀⠀⢠⠋⠀⠀⠀⠀⠀
⠻⣿⣦⣿⡟⢸⣿⣧⣼⡿⣸⣿⡀⢻⣿⢇⣿⣇⠙⣿⡇⣰⣟⠛⣿⣿⡄⠀⠀⣸⡇⠻⣦⣄⣀⣀⣠⡀⠀⠀⣸⠀⠀⠀⠀⠀⠀
⠀⠙⠛⠋⠀⠀⠙⠛⠋⠀⠙⠛⠁⠀⠙⠈⠛⠁⠀⠈⠋⠛⠋⠈⠛⠛⠁⠀⠀⣿⣷⡄⠈⢻⣿⣿⣿⣷⣾⣿⣿⡄⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢿⣿⣿⣦⠼⠿⠿⠿⠿⠿⣿⣿⡷⠀⠀⠀⠀⠀
⠀⠀⣀⣠⣀⡀⣀⣀⣀⣀⣀⡀⣀⣀⣀⣀⣀⣠⠀⠀⣀⣀⣀⣀⣀⡀⢀⣀⣀⡀⣀⣀⣀⣀⣀⡀⠀⣀⣀⣀⢀⣀⣀⣀⣀⣀⡀
⢀⣾⣿⠋⢿⠇⢹⣿⡏⢹⣿⡇⣽⠏⢹⣿⢫⡽⠃⠀⠙⢿⣯⣾⠋⣴⣿⠋⣿⣷⢹⣿⠏⠘⣿⠁⠀⢹⣿⡏⠈⣿⠉⣿⡏⣿⣿
⢸⣿⣇⢶⣶⣶⣸⣿⠃⠈⣿⣷⡟⠀⣼⣿⢻⢧⡀⠀⠀⢸⣿⡇⢰⣿⣿⢀⣿⡿⣾⣿⠀⢸⡿⠀⠀⣼⣿⠀⢰⡿⢰⣿⡿⠟⠃
⠀⠻⠿⠿⠛⠱⠿⠿⠆⠀⠹⠟⠀⠰⠿⠿⠶⠿⠃⠀⠀⠿⠿⠗⠀⠻⠿⠾⠟⠁⠙⠿⠿⠿⠃⠀⠀⠙⠿⠿⠿⠁⠿⠿⠷⠀⠀
`)