class Script{
	constructor(src="",type="text/javascript"){
		const script=document.createElement("script");
		script.src=src;
		script.type=type;
		script.async=true;
		script.defer=true;
		//script.crossOrigin="anonymous";
		document.head.append(script);
		return script;
	}
}

window.addEventListener("load",function(){
	new Script("https://www.google.com/recaptcha/api.js");
});