let elapsed=performance.now();
let last=performance.now();
const maxFPS=60;

export const animate = {
    objects: [],
    update: function (timestamp) {
		elapsed=timestamp-last;
		if(elapsed>=1000/maxFPS){
			last=timestamp-(elapsed%(1000/maxFPS));
			for (let i = 0; i < animate.objects.length; i++) {
				let e = animate.objects[i];
				if (e.destroy) {
					animate.objects.splice(i, 1);
					i--;
				} else {
					e.update();
				}
			}
		}
        requestAnimationFrame(animate.update);
    }
}
animate.update();
window.animate = animate;