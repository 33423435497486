window.disableProducts = function () {
    $('section#products').addClass('loading');
}
window.enableProducts = function () {
    $('section#products').removeClass('loading');
}


$(".search-button").on("click", function (event) {
    disableProducts();
    $(".main-search-container").addClass("active");
    setTimeout(function () {
        $(".main-search-container input")[0].focus();
    }, 100);
});

$(".main-search-container .close").on("click", function (event) {
    enableProducts();
    $(".main-search-container").removeClass("active");
});

$(".main-search-container").on("click", function (event) {
    $(".main-search-container").removeClass("active");
});

$(".main-search-container input").on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();
})

$(window).on("keydown", function (event) {
    if (event.key == "Escape") {
        $(".main-search-container").removeClass("active");
    }
});


$("a.mouse-icon-container").on("click", function (event) {
    event.preventDefault();
    $("html, body").animate({
        scrollTop: $(this.getAttribute("href")).offset().top - $("header").height()
    }, 500);


    $("a.mouse-icon-container").on("click",function(event){
        event.preventDefault();
        $("html, body").animate({
            scrollTop: $(this.getAttribute("href")).offset().top-$("header").height()
        }, 500);

        return false;
    });
});