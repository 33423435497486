// import {animate} from "./animate.js";

import { VcAnimation } from '../allsenses/js/vc-animation.js';
import {$} from './utils.js';

$(window).on("load", function() {
    $("#nav-icon").on("click", function() {
        $("#main-menu").toggleClass("active");
        $("#nav-icon").toggleClass("active");
    });

	$("video").each(function(){
		if($(this).attr("data-autoplay")=="true"){
			this.play();
		}
	});

	$("video.video-main").on("ended",function(){
		$(".video-description").addClass("active");
	});
});


$(window).on("load", function() {
    loadAllMain();

    $(window).on("scroll", function() {
        loadAllMain();
    });

    scrollTo(0, window.scrollY);

    $("a[data-smooth-scroll]").on("click", function(e) {
        // Smooth scroll to element
        const href = $(this).attr("href");
        const target = $(href);

        if (target.length > 0) {
            e.preventDefault();
            $(window).scrollTo(0, target.get(0).getBoundingClientRect().top + window.scrollY - 50, 500);
        }
    })

    $("textarea[value]").each(function() {
        $(this).val($(this).attr("value"));
    });

    // Modal
    $("[data-modal]").on("click", function() {
        const modal = $(`#${$(this).attr("data-modal")}`);
        modal.toggleClass("active");

        if (modal.hasClass("active")) {
            $(".modal-background").addClass("active");
        } else {
            $(".modal-background").removeClass("active");
        }
    });

    $("[data-modal-close]").on("click", function() {
        const modal = $(this).closest(".modal");
        modal.removeClass("active");
        $(".modal-background").removeClass("active");
    });

    const modalBackground = document.createElement("div");
    modalBackground.classList.add("modal-background");
    document.body.appendChild(modalBackground);

    $(modalBackground).on("click", function() {
        $(".modal.active").removeClass("active");
        $(this).removeClass("active");
    });


    // Dropdown
    $(".dropdown").each(function() {
        const content = $(this).find(".content");

        $(this).on("click", function() {
            $(this).toggleClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("blur", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });

        $(this).on("focus", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseenter", function() {
            $(this).addClass("active");
            content.css("max-height", content.css("max-height") ? null : content.prop("scrollHeight") + "px");
        });

        $(this).on("mouseleave", function() {
            $(this).removeClass("active");
            content.css("max-height", null);
        });
    });


    // Accordion
    $(".accordion > .title").on("click", function() {
        $(this).parent().toggleClass("active");
        $(this).parent().removeClass("pre-active");
        const item = $(this).next();
        if ($(this).parent().hasClass("active")) {
            item.css("max-height", item.prop("scrollHeight") + "px");
        } else {
            item.css("max-height", `0px`);
        }
    });


    // Tooltip
    const tooltips = document.createElement("div");
    tooltips.classList.add("tooltip");
    document.body.appendChild(tooltips);

    $("[data-tooltip]").on("mouseenter", function() {
        tooltips.innerHTML = $(this).attr("title");
        tooltips.addClass("active");
        const type = $(this).attr("data-tooltip") || "bottom";
        if (type) {
            let rect = this.getBoundingClientRect();
            let top = rect.top + window.scrollY;
            let left = rect.left + window.scrollX;
            let width = rect.width;
            let height = rect.height;

            let tooltipWidth = tooltips.offsetWidth;
            let tooltipHeight = tooltips.offsetHeight;

            let offset = 10;

            if (type === "top") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top - tooltipHeight - offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "bottom") {
                let x = left + width / 2 - tooltipWidth / 2;
                let y = top + height + offset;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "left") {
                let x = left - tooltipWidth - offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            } else if (type === "right") {
                let x = left + width + offset;
                let y = top + height / 2 - tooltipHeight / 2;

                if (0 > x) x = 0;
                if (0 > y) y = 0;

                tooltips.style.left = x + "px";
                tooltips.style.top = y + "px";
            }

            tooltips.classList.add(type);
        }
    });

    $("[data-tooltip]").on("mouseleave", function() {
        tooltips.removeClass("active");
        tooltips.removeClass("top");
        tooltips.removeClass("bottom");
        tooltips.removeClass("left");
        tooltips.removeClass("right");
        tooltips.style = "";
    });

});

window.vcanimations = [];
const loadAllMain = window.loadAllMain = function() {
    // Load VcAnimation
    $("[vcanimation]").each(function() {
        if ($(this).attr("vcanimation") == "loaded") return;

        const aniData = $(this).attr("vcanimation");
        const json = JSON.parse(aniData);

        for (const jsonElement of json) {
            const animation = new VcAnimation(this, jsonElement);
            window.vcanimations.push(animation);
        }
        $(this).attr("vcanimation", "loaded");
    });

    $("iframe[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("src", obj.attr("data-src"));
            obj.removeData("src");
        }
    });

    $("img[data-src]").each(async function() {
        let obj = $(this);
        if (obj.isInViewport(1000) || obj.attr("forceload")) {
            if (obj.attr("data-src").endsWith(".svg")) {
                if (obj.attr("data-src-loading") == "true") return;
                obj.attr("data-src-loading", true);

                fetch(obj.attr("data-src")).then(response => response.text()).then((response) => {

                    // If parent of image is picture
                    let replaceObj = obj;
                    if (obj.parent().is("picture")) {
                        replaceObj = obj.parent();
                    }

                    // Replace outerHTML of image with SVG, and copy all attributes
                    const classes = replaceObj.attr("class");
                    const id = replaceObj.attr("id");

                    // Change response to element
                    const parser = new DOMParser();
                    const svg = parser.parseFromString(response, "text/html").querySelector("svg");

                    // Copy attributes
                    if (classes) svg.setAttribute("class", classes);
                    if (id) svg.setAttribute("id", id);

                    // Replace outerHTML
                    replaceObj.outerHTML(svg.outerHTML);

                }).catch(error => function() {
                    console.log("Error loading SVG", error);
                });

            } else {
                /*                obj.attr("src", obj.data("src"));
                                obj.on("error", function () {
                                    obj.attr("src", "/th/sm/UNKNOWN");
                                });
                                obj.removeData("src");

                                obj.on("load", function () {
                                    let preloader = $(this).findParent(".preloader");
                                    if (preloader && preloader.length > 0 && preloader[0]) {
                                        preloader.removeClass("loading").removeClass("preloader")
                                    }
                                });*/

                const url = obj.data("src");
                obj.removeData("src");

                // Create blob with image then parse to img element (async)
                const promise = new Promise((resolve, reject) => {
                    fetch(url)
                        .then(response => response.blob())
                        .then(imageBlob => {
                            const imageObjectURL = URL.createObjectURL(imageBlob);
                            resolve(imageObjectURL);
                        }).catch((err) => {
                        reject(err);
                    });
                });

                promise.then((blobUrl) => {
                    obj.attr("src", blobUrl);
                    obj.on("error", function() {
                        obj.attr("src", "/th/sm/UNKNOWN");
                    });
                    obj.on("load", function() {
                        if (obj.parent().hasClass("lazy")) {
                            obj.parent().removeClass("lazy");
                        }
                    });
                }).catch((data) => {
                    console.error("Error while loading image", data);
                });
            }
        }
    });

    $("[data-bg]").each(async function() {
        const obj = $(this);
        const src = obj.attr("data-bg");
        if (obj.isInViewport(1000)) {
            obj.attr("style", `background-image:url(${src})`);
        }
    });

    $("source[data-src]").each(function() {
        let obj = $(this);
        if (obj.isInViewport(1000)) {
            obj.attr("srcset", obj.data("src"));
            obj.on("error", function() {
                obj.attr("srcset", "/th/sm/UNKNOWN");
            });
            obj.removeData("src");
        }
    });

    interchange();
};

$("#cookie .close-cookie").on("pointerup", function() {
    $.cookie("COOKIE", true, 90);
    const cookie = $.cookie("COOKIE");
    if (cookie != "") {
        $("#cookie").addClass("hide");
    }
});

const cookie = $.cookie("COOKIE");
if (cookie != "") {
    $("#cookie").addClass("hide");
}