$(window).on("load", function() {

    $(".nav-sm-burger, .nav-md-burger, .nav-lg-burger, .nav-xlg-burger, .nav-full-burger").each(function() {
        $(this).append(` 
            <div class="nav-burger"> 
                <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="burger">
                        <path id="l3" d="M26.25 21.5C27.216 21.5 28 20.716 28 19.75C28 18.784 27.216 18 26.25 18H15.75C14.784 18 14 18.784 14 19.75C14 20.716 14.784 21.5 15.75 21.5H26.25Z" fill="var(--color-black)"/>
                        <path id="l2" d="M28 11C28 11.966 27.216 12.75 26.25 12.75H1.75C0.784 12.75 0 11.966 0 11C0 10.034 0.784 9.25 1.75 9.25H26.25C27.216 9.25 28 10.034 28 11Z" fill="var(--color-black)"/>
                        <path id="l1" d="M28 2.25C28 3.216 27.216 4 26.25 4H8.75C7.784 4 7 3.216 7 2.25C7 1.284 7.784 0.5 8.75 0.5H26.25C27.216 0.5 28 1.284 28 2.25Z" fill="var(--color-black)"/>
                    </g>
                </svg>
              </div>
        `);

        const burger = $(this).find('.nav-burger');

        burger.on('click', function() {
            $(this).toggleClass('active');
            $(this).parent().find(".nav-menu").toggleClass('active');
        });
    });

    $(".has-submenu").on('mouseenter', function() {
        $(this).addClass('active');
    });

    $(".has-submenu").on('mouseleave', function() {
        $(this).removeClass('active');
    });

});