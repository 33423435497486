import {$} from "../../js/utils.js";

window.addEventListener("load", function () {
	$(".loader-container").removeClass("active");

    $("a[href]").on("click",function(event){
		const href = $(this).attr("href");
		const target=$(this).attr("target");
		
		if(!href.startsWith("#") && (!target || target=="_self") && !$(this).hasClass("image") && !$(this).hasClass("open")){
			$(".loader-container").addClass("active");
			const timeout=parseFloat(getComputedStyle($(".loader-container")[0])["-webkit-transition-duration"])*1000;
	
			setTimeout(function(){
				location.href=href;
			},timeout);
			event.preventDefault();
		}
	});
});

/*
$("a").on("click", function () {
    if (!$(this).hasClass("simple-image") && !$(this).hasClass("open")) {
        var e = $(this).attr("href"),
            t = $(this).attr("target");
        if (null == e) return !1;
        if ("_blank" == t) return !0;
        if (console.log(e.indexOf("#")), "" != e && 0 != e.indexOf("#")) return $(".animator").removeClass("load"), setTimeout(function () {
            return location.assign(e), !0
        }, 350), !1
    }
});*/
