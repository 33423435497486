import {$} from "./utils.js";

$(window).on("load",function(){
	if($(".allsenses-gallery").length==0) return;
	const gallery=document.createElement("div");
	gallery.className="gallery-container";
	gallery.innerHTML=`
		<div class="content">
			<div class="loader active"></div>
			<div class="target"></div>
			<a class="prev"></a>
			<a class="next"></a>
		</div>`;
	$("body").append(gallery);

	setTimeout(function(){
		$(gallery).addClass("show");
	},300)

	$(gallery).on("click",function(event){
		if(event.target==this){
			$(gallery).removeClass("active");
			$(gallery).find("*").removeClass("active");
		}
	})

	$(".allsenses-gallery").each(function(){
		this.gallery=[];
	})

	$(".allsenses-gallery").each(function(){
		const that=this;
		$(this).find("img").each(function(i){
			this.gallerId=i;
			that.gallery.push(this);
		});
	})
	
	const current={
		gallery:null,
		id:null
	}
	$(".allsenses-gallery").each(function(){
		const that=this;
		$(this).find("img").on("click",function(event){
			current.gallery=that.gallery;
			current.id=this.gallerId;
			changeImage.call(this);
		});	
	})
	$(gallery).find(".prev").on("click",prev);
	$(gallery).find(".next").on("click",next);

	function prev(){
		const l=current.gallery;
		if(current.id>0){
			current.id-=1;
			changeImage.call(l[current.id]);
		}
	}

	function next(){
		const l=current.gallery;
		if(current.id<l.length-1){
			current.id+=1;
			changeImage.call(l[current.id]);
		}
	}

	function resize(event){
		const content=$(gallery).find(".content")[0];
		const padding=parseFloat(getComputedStyle(content)["padding"])*2;
		
		if($(gallery).find("img")==0) return;
		const rect=$(gallery).find("img").rect();
		const w=rect.width+padding;
		const h=rect.height+padding;
		if(event){
			$(gallery).find(".content").attr("style",`-webkit-transition:0s;width:${w}px;height:${h}px`);
		}else{
			$(gallery).find(".content").attr("style",`width:${w}px;height:${h}px`);
		}
	}
	$(window).on("resize",resize);


	function keyboard(event){
		if($(gallery).hasClass("active")){
			if(event.key=="ArrowLeft"){
				prev();
			}
			if(event.key=="ArrowRight"){
				next();
			}
		}
	}

	const gesture={
		start:0,
		end:0
	}
	$(gallery).find(".content").on("pointerdown",function(event){
		event.preventDefault();
		gesture.start=event.clientX;
	});
	$(gallery).find(".content").on("pointerup",function(event){
		gesture.end=event.clientX;
		const distance=Math.abs(gesture.start-gesture.end);
		if(distance>48){
			if(gesture.start<gesture.end){
				prev();
			}else{
				next();
			}
		}
	});

	$(window).on("keydown",keyboard);

	function changeImage(){
		$(gallery).find(".content .loader").addClass("active");
		$(gallery).addClass("active");
		let src=null;
		src=this.src.split("/");
		const img=new Image();
		let file=src[src.length-2];
		if(file.indexOf(".")==-1){
			file=src[src.length-1];
		}
		img.src="/th/org/"+file;
		$(img).on("load",function(){
			$(gallery).find(".content .target").html("");
			const content=$(gallery).find(".content")[0];
			const transition=parseFloat(getComputedStyle(content)["transition-duration"])*1000;
			$(gallery).find(".content .target").append(img);
			
			resize(false);
			
			setTimeout(function(){
				$(gallery).find(".content .loader").removeClass("active");
				$(img).addClass("active");
			},transition)
		});
	}
})