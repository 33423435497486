$(window).on("load", function () {
    $(window).on("scroll", function () {
        checkAnimation();
    });
   checkAnimation();
});

function checkAnimation() {
    $(".typing-animation").each((i, obj) => {
        obj = $(obj);
        let speed = obj.data("typing") || 40;
        let delay = parseInt(obj.data("typingdelay") || 50);

        obj.removeData("typing");
        obj.removeData("typingdelay");

        typingAnimation(obj, speed, delay);
    });
}

function typingAnimation(obj, speed, delay) {
    if (obj.isInViewport(200) && !obj.hasClass("typing-init") && !obj.hasClass("typing-done")) {
        obj.addClass("typing-init").typingEffect(speed, delay, function (e) {
            this.removeClass("typing-init").addClass("typing-done");
        })
    }
}