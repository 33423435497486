import { Counter } from "./counter.js";
import { animate } from "./animate.js";
import "./gallery.js";

$(window).on("load", function() {

	let counters = $(".counter");
	$(window).on("scroll", function() {
		counters.each(function(i, obj) {
			let el = $(obj);
			if (el.isInViewport(-100) && !el.hasClass("counter-init") && !el.hasClass("counter-done")) {
				let max = Number(el.attr("data-max")) || 0;
				let min = Number(el.attr("data-min")) || 0;
				let duration = Number(el.attr("data-duration")) || 1000;
				let prefix = el.attr("data-prefix") || "";
				let suffix = el.attr("data-suffix") || "";
				let thousandSeparator = el.attr("data-thousandseparator") || false;


				el.addClass("counter-init");
				animate.objects.push(
					new Counter(el, min, max, duration, prefix, suffix, thousandSeparator, function(obj) {
						obj.removeClass("counter-init").addClass("counter-done");
					}),
				);
			}
		});
	});

	window.swiperData = [];
	$("[data-swiper]").each(function() {
		if ($(this).attr("data-swiper")) {
			let data = JSON.parse($(this).attr("data-swiper"));
			window.swiperData.push(new Swiper(this, data));
		}
	});

	const interchange = window.interchange = () => {
		$("[data-horizontal]").each(function() {
			const horizontal = $(this).data("horizontal");
			const vertical = $(this).data("vertical");
			if ($(this).isInViewport(1000)) {
				let url = null;
				if (window.innerWidth >= 1024) {
					if ($(this).attr("type") == "horizontal") return;
					$(this).attr("type", "horizontal");
					url = horizontal || null;
				} else {
					if ($(this).attr("type") == "vertical") return;
					$(this).attr("type", "vertical");
					url = vertical || null;
				}

			
				$(this).css("background-image", `url(${url})`);
				
			}
		});
	};

	interchange();
	$(window).on("resize", interchange);


	let l = document.querySelectorAll(".tabs-container a.tab-button");
	for (let i = 0; i < l.length; i++) {
		let e = l[i];
		if (e.classList.contains("active")) {
			$($(e.parentNode.parentNode).find(".tab-cell")[i]).addClass("active");
		}
		$(e).on("click", function(event) {
			let parent = this.parentNode.parentNode;
			$(parent).find(".tab-cell").removeClass("active");
			$($(parent).find(".tab-cell")[i]).addClass("active");
			$(this.parentNode).find("a.tab-button").removeClass("active");
			$(this).addClass("active");

			event.preventDefault();
			return false;
		});
	}
});