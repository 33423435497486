setTimeout(function(){
    $(".fade-box .fade-container").each(function () {

        const textCords = $(this).find(".fade-text .fade-description").rect();

        $(this).find(".fade-text").css("transform", "translateY(" + textCords.height + "px)");

        this.addEventListener("mouseleave", pointerOut)
        this.addEventListener("mouseenter", function (event) {
            $(this).find(".fade-text").addClass("active");
            $(this).find(".fade-text").css("transform", "none");
        });

        // Add mobile support
        this.addEventListener("touchstart", function (event) {
            $(".fade-text").removeClass("active");
            $(this).find(".fade-text").addClass("active");
            $(this).find(".fade-text").css("transform", "none");
        });
    });
}, 1000);

function pointerOut(event) {
    const textCords = $(this).find(".fade-text .fade-description").rect();
    $(this).find(".fade-text").removeClass("active");
    $(this).find(".fade-text").css("transform", "translateY(" + textCords.height + "px)");
}